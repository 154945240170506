const SUBSCRIBERS_REDUX_CONSTANTS = {
  GET_ALL_SUBSCRIBERS: 'GET_ALL_SUBSCRIBERS',
  UPDATE_SUBSCRIBERS: 'UPDATE_SUBSCRIBERS',
  GET_SUBSCRIBERS_BYID: 'GET_SUBSCRIBERS_BYID',
  GET_INDUSTRIES: 'GET_INDUSTRIES',
  GET_COMPANY_SIZE: 'GET_COMPANY_SIZE',
  DELETE_SUBSCRIBERS: 'DELETE_SUBSCRIBERS',
  INVITEE_SUBSCRIBERS: 'INVITEE_SUBSCRIBERS',
  ADD_INVITEE_SUBSCRIBER: 'ADD_INVITEE_SUBSCRIBER',
  DELETE_INVITEE_SUBSCRIBER: 'DELETE_INVITEE_SUBSCRIBER',
  EDIT_INVITEE_BY_ID: 'EDIT_INVITEE_BY_ID',
  RESET_SELECTED_INVITEE:'RESET_SELECTED_INVITEE',
  RESET_SUBSCRIBER_INFO: 'RESET_SUBSCRIBER_INFO'
};
export default SUBSCRIBERS_REDUX_CONSTANTS;
