const ADMIN_REDUX_CONSTANTS = {
  GET_ADMIN_DATA: 'GET_ADMIN_DATA',
  GET_ADMIN_BY_ID: 'GET_ADMIN_BY_ID',
  ADD_NEW_ADMIN: 'ADD_NEW_ADMIN',
  UPDATE_ADMIN: 'UPDATE_ADMIN',
  DELETE_ADMIN: 'DELETE_ADMIN',
  GENERATE_2FA: 'GENERATE_2FA',
  CHANGE_PASS: 'CHANGE_PASS',
  ENABLE_LOADER: 'ENABLE_LOADER'
};
export default ADMIN_REDUX_CONSTANTS;
